import { default as React, useState, useEffect } from "react";
import { Header1, Header3, Paragraph } from "components/Typography";
import { Form, Radio, TextArea, List } from "semantic-ui-react";
import { CommonContentBlock } from "./ContentBlock";
import { MainModal } from "../components/MainModal";
import CurisButton from "components/CurisButton";
import styled from "styled-components";
import { COLORS } from "./STYLE_CONFIG";
import axios from "axios";
import { BASE_URL } from "resources/api";
import Cookies from "universal-cookie";
import { useResource } from "rest-hooks";
import SettingsResource from "resources/settings";
import ProfileResource from "resources/profile";
import { ErrorScreen } from "./ErrorScreen";
import UserResource from "resources/user";
import { Toaster, toast } from "react-hot-toast";
import OptionsResource from "resources/options";

export const PageWrapper = styled.div`
    padding: 50px 140px;
    background: white;
`;

export const AdminPage: React.FC = () => {
  const [settingsList, profiles, user, options] = useResource(
    [SettingsResource.listShape(), {}],
    [ProfileResource.listShape(), {}],
    [UserResource.detailShape(), {}],
    [OptionsResource.detailShape(), {}]
  );

  useEffect(() => {
    console.log(profiles);
  }
  , [profiles]);


  const [year, setYear] = useState(options.academic_year_options[0].value);
  const [quarter, setQuarter] = useState(options.quarter_options[0].value);

  const settings = settingsList[0];

  const [curisFellowsToggle, setCurisFellowsToggle] = useState(false);
  const [curisMatchingToggle, setCurisMatchingToggle] = useState(false);
  const [projectEmailsToggle, setProjectEmailsToggle] = useState(false);

  const [projectAppsToggle, setProjectAppsToggle] = useState(false);

  const [emails, setEmails] = useState("");
  const [emailsToRemove, setEmailsToRemove] = useState("");

  const defaultStudentRound = settings.allow_only_curis_fellows ? "1" : "2";
  const defaultFacultyRound = settings.faculty_review_only_curis_fellows_enabled
    ? "1"
    : "2";

  const [studentRound, setStudentRound] = useState(defaultStudentRound);
  const [facultyRound, setFacultyRound] = useState(defaultFacultyRound);
  const [matchingRound, setMatchingRound] = useState(defaultStudentRound);

  const cookies = new Cookies();

  const admin_academic_years = [
    ["2021-2022", "2021-2022"],
    ["2022-2023", "2022-2023"],
    ["2023-2024", "2023-2024"],
    ["2024-2025", "2024-2025"],
  ];
  const admin_academic_year_options = admin_academic_years.map((option) => ({
      key: option[0],
      text: option[1],
      value: option[0],
  }));

  if (!user.has_admin_access)
    return (
      <ErrorScreen
        header="Page not available"
        message="The page you were trying to access is only available to CURIS Staff."
        showReturn
      />
    );

  const onRunMatchingAlgorithm = () => {
    axios
      .get(BASE_URL + "/api/settings/match/", {
        headers: { Authorization: `Token ${cookies.get("authToken")}` },
        responseType: "blob",
        params: { matching_round: matchingRound },
      })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "matching_algorithm_results.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error("Operation failed. Check input, and try again!");
      });
  };

  const onSaveSettings = () => {
    axios
      .post(
        BASE_URL + "/api/settings/configuration/",
        {
          emails,
          studentRound,
          facultyRound,
          emailsToRemove,
        },
        {
          headers: { Authorization: `Token ${cookies.get("authToken")}` },
        }
      )
      .then((resp) => {
        toast.success("Settings successfully saved!");
      })
      .catch((err) => {
        toast.error("Operation failed. Check input, and try again!");
      });
  };

  const onExportEmails = () => {
    if (year && quarter) {
      axios
        .get(
          BASE_URL +
            `/api/settings/export_emails/?year=${year}&quarter=${quarter}`,
          {
            headers: { Authorization: `Token ${cookies.get("authToken")}` },
            responseType: "blob",
          }
        )
        .then((resp) => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "project_emails.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          toast.error("Operation failed. Check input, and try again!");
        });
    }
  };

  const onExportApps = () => {
    if (matchingRound) {
      axios
        .get(
          BASE_URL +
            `/api/settings/export_apps/?is_curis_fellow=${matchingRound}`,
          {
            headers: { Authorization: `Token ${cookies.get("authToken")}` },
            responseType: "blob",
          }
        )
        .then((resp) => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "proj_applications.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          toast.error("Operation failed. Check input, and try again!");
        });
    }
  };

  return (
    <PageWrapper>
      <Toaster position="top-right" reverseOrder={true} />
      <Header1>CURIS Admin Portal</Header1>
      <CommonContentBlock
        header="Export project contact info"
        description={
          <>
            <div style={{ marginBottom: "30px" }}>
              <Paragraph>Export project contact information (project names, emails, etc.)</Paragraph>
            </div>
            <CurisButton onClick={() => setProjectEmailsToggle(true)}>
              Export Project Info
            </CurisButton>
          </>
        }
      />

      <CommonContentBlock
        header="Export project applications"
        description={
          <>
            <div style={{ marginBottom: "30px" }}>
              <Paragraph>Export project applications (student and faculty rankings for all projects)</Paragraph>
            </div>
            <CurisButton onClick={() => setProjectAppsToggle(true)}>
              Export Application Info
            </CurisButton>
          </>
        }
      />

      <CommonContentBlock
        header="Run CURIS Matching"
        description={
          <>
            <div style={{ marginBottom: "30px" }}>
              <Paragraph>Run matching algorithm to match students to CURIS projects</Paragraph>
            </div>
            <CurisButton onClick={() => setCurisMatchingToggle(true)}>
              Run Matching
            </CurisButton>
          </>
        }
      />

      <CommonContentBlock
        header="Manage CURIS Fellows"
        description={
          <>
            <div style={{ marginBottom: "30px" }}>
              <Paragraph>Manage the set of CURIS Fellows who receive guaranteed funding</Paragraph>
            </div>
            <CurisButton onClick={() => setCurisFellowsToggle(true)}>
              Manage Fellows
            </CurisButton>
          </>
        }
      />

      <MainModal
        toggle={curisFellowsToggle}
        onClose={() => setCurisFellowsToggle(false)}
        headerText="Manage CURIS Fellows"
      >
        
        <div style={{ marginBottom: "30px"}}>
          <Paragraph>
            Manage the set of CURIS Fellows who receive guaranteed funding.
          </Paragraph>
        </div>
        
        <div style={{ marginBottom: "30px"}}>
          <Header3 color={COLORS.BLACK}>Current CURIS Fellows</Header3>
          <List as="ol">
            {profiles.map((profile, index) => {
              if (profile.is_curis_fellow) {
                console.log("CURIS Fellow:", profile);
              }
              return profile.is_curis_fellow ? (
                <List.Item key={`key-${index}`} as="li">
                      {`${profile.name} (${profile.sunet})`}
                </List.Item>
              ) : null;
            })}
          </List>
        </div>

        <Header3 color={COLORS.BLACK}>
          Specify CURIS Fellows (comma-separated list of emails)
        </Header3>
        <Form style={{ marginBottom: "30px" }}>
          <StyledTextArea
            onChange={(e: any) => {
              setEmails(e.target.value);
            }}
            placeholder="Enter a comma seperated list of emails of curis fellows here."
            value={emails}
          />
        </Form>

        <Header3 color={COLORS.BLACK}>
          Specify CURIS Fellows To Remove (comma-separated list of emails)
        </Header3>
        <Form style={{ marginBottom: "30px" }}>
          <StyledTextArea
            onChange={(e: any) => {
              setEmailsToRemove(e.target.value);
            }}
            placeholder="Enter a comma seperated list of emails of curis fellows here."
            value={emailsToRemove}
          />
        </Form>

        <div style={{ marginTop: "30px" }}>
          <CurisButton onClick={() => onSaveSettings()}>
            Save Settings
          </CurisButton>
        </div>
      </MainModal>

      <MainModal
        toggle={curisMatchingToggle}
        onClose={() => setCurisMatchingToggle(false)}
        headerText="Run CURIS Matching"
      >
        <div>
          <Paragraph>
            Run the match algorithm and export a CSV containing the student name, SUNet, and project name from the matching results.
          </Paragraph>
          <Form style={{ marginBottom: "30px", marginTop: "30px" }}>
            
	  </Form>
          <CurisButton onClick={() => onRunMatchingAlgorithm()}>
            Run Matching
          </CurisButton>
        </div>
      </MainModal>

      <MainModal
        toggle={projectEmailsToggle}
        onClose={() => setProjectEmailsToggle(false)}
        headerText="Export project emails"
      > 
        <Paragraph>
          Select the Academic Year and Quarter and export a CSV containing the project name, contact name, and contact email associated with all projects.
        </Paragraph>
        <Form style={{ marginBottom: "30px", marginTop: "30px" }}>
          <StyledSelect
            name="Year"
            options={admin_academic_year_options}
            placeholder="Select Academic Year"
            label="Select Academic Year"
            value={year}
            onChange={(
              e: any,
              { name, value }: { name: string; value: string }
            ) => {
              setYear(value);
            }}
          />
          <StyledSelect
            name="Quarter"
            options={options.quarter_options}
            placeholder="Select Quarter"
            label="Select Quarter"
            value={quarter}
            onChange={(
              e: any,
              { name, value }: { name: string; value: string }
            ) => {
              setQuarter(value);
            }}
          />
          <div>
            <CurisButton onClick={() => onExportEmails()}>
              Export project emails
            </CurisButton>
          </div>
        </Form>
      </MainModal>

      <MainModal
        toggle={projectAppsToggle}
        onClose={() => setProjectAppsToggle(false)}
        headerText="Export project applications"
      > 
        <Paragraph>
          Export a CSV containing all student and project information, as well as student and faculty rankings for each project application.
        </Paragraph>
        <Form style={{ marginBottom: "30px", marginTop: "30px" }}>
	  <div>
            <CurisButton onClick={() => onExportApps()}>
              Export project applications
            </CurisButton>
          </div>
        </Form>
      </MainModal>

    </PageWrapper>
  );
};

const StyledSelect = styled(Form.Select)`
  font-family: Lato !important;
  font-size: 18px !important;
  line-height: 29px !important;
  // font-weight: 600 !important;
  color: ${COLORS.DARK_GREY} !important;

  & label {
    font-family: Lato !important;
    font-size: 18px !important;
    line-height: 29px !important;
    // font-weight: 600 !important;
  }

  & input {
    padding: 13px !important;
  }
`;

const StyledTextArea = styled(TextArea)`
  font-family: Lato !important;
  // font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 29px !important;
  padding: 13px !important;

  min-height: 161px;
  margin-top: 6px;
`;
