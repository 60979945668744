import { default as React, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import OptionsResource from "resources/options";
import ProjectResource from "resources/project";
import UserResource from "resources/user";
import { useFetcher, useResource } from "rest-hooks";
import { Button, Form, Icon, Loader, Modal } from "semantic-ui-react";
import styled from "styled-components";
import CurisButton from "./CurisButton";
import { ProjectType } from "./ProjectsPage";
import { COLORS } from "./STYLE_CONFIG";
import Typography, { Header2, Header3, Paragraph } from "./Typography";
import { Link } from "react-router-dom";

interface Values {
  Description?: string;
  Name?: string;
  Number_of_students?: number;
  Number_of_funded_students?: number;
  Prerequisites?: string;
  Project_url?: string;
  Recommened_background?: string;
  Professor_name?: string;
  Professor_email?: string;
  Public_OH?: string;
  Outreach_OH?: string;
}

const required = (value: string | undefined) =>
  value ? undefined : "Required";
const email = (value: string | undefined) =>
  /^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(value || "")
    ? undefined
    : "Please enter a valid email address";

const composeValidators =
  (...validators: any[]) =>
  (value: string | undefined) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export const CreateProjectModal: React.FC<{
  projectType: ProjectType;
  onClose: () => void;
}> = ({ projectType, onClose }) => {
  const options = useResource(OptionsResource.detailShape(), {});
  const user = useResource(UserResource.detailShape(), {});

  const create = useFetcher(ProjectResource.createShape());

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [created, setCreated] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const [department, setDepartment] = useState(
    projectType === ProjectType.NON_CS ? "" : "CS"
  );
  const [compensationType, setCompensationType] = useState(
    projectType === ProjectType.SUMMER ? "paid" : ""
  );
  const [quarter, setQuarter] = useState(
    projectType === ProjectType.SUMMER ? "summer" : ""
  );
  const [year, setYear] = useState("");
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const [field3, setField3] = useState("");

  const onSubmit = async ({
    Professor_email,
    Professor_name,
    Description,
    Name,
    Number_of_students,
    Number_of_funded_students,
    Prerequisites,
    Project_url,
    Recommened_background,
    Public_OH,
    Outreach_OH,
  }: Values) => {
    setError(false);
    setCreated(false);
    setShowErrors(false);
    if (
      !department ||
      !compensationType ||
      !quarter ||
      !year ||
      (!field1 && projectType !== ProjectType.NON_CS)
    ) {
      setShowErrors(true);
      return;
    }
    setLoading(true);
    try {
      await create(
        {},
        {
          compensation_type: compensationType,
          contact_professor_email: Professor_email,
          contact_professor_name: Professor_name,
          department,
          description: Description,
          field_of_research: field1,
          field_of_research2: field2,
          field_of_research3: field3,
          name: Name,
          number_of_students: Number_of_students,
	  number_of_funded_students: Number_of_funded_students,
          outreach_office_hours: Outreach_OH,
          prerequisites: Prerequisites,
          project_url: Project_url,
          public_office_hours: Public_OH,
          recommended_background: Recommened_background,
          school_quarter: quarter,
          school_year: year,
        },
        [
          [
            ProjectResource.listShape(),
            {},
            (newId: string, oldsIDs: string[] | undefined) => [
              ...(oldsIDs || []),
              newId,
            ],
          ],
        ]
      );
      setError(false);
      setCreated(true);
      setLoading(false);
      // onClose();
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderWrapper>
        <Header2 color={COLORS.BLACK}>Create a new project listing</Header2>
        <Icon
          onClick={() => {
            onClose();
          }}
          style={{ color: COLORS.MED_GREY }}
          link
          name="close"
          size="large"
        />
      </HeaderWrapper>
      <Form style={{ marginTop: "40px" }}>
        <FinalForm
          onSubmit={onSubmit}
          initialValues={{
            Professor_name: user.prof_name,
            Professor_email: user.prof_email,
            Number_of_students: 0,
	    Number_of_funded_students: 0,
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Field name="Name" validate={required}>
                {({ input, meta }) => (
                  <div style={{ marginBottom: "18px" }}>
                    <StyledInput
                      name={input.name}
                      label="Project Name*"
                      value={input.value}
                      onChange={input.onChange}
                      error={meta.error && meta.touched && meta.error}
                    />
                  </div>
                )}
              </Field>
              <StyledSelect
                search
                error={showErrors && !department}
                disabled={projectType !== ProjectType.NON_CS}
                name="Department"
                options={
                  projectType === ProjectType.NON_CS
                    ? options.non_cs_department_options
                    : options.department_options
                }
                placeholder="Select a department"
                label="Department*"
                value={department}
                onChange={(
                  e: any,
                  { name, value }: { name: string; value: string }
                ) => {
                  setDepartment(value);
                }}
              />
              <StyledSelect
                error={showErrors && !compensationType}
                name="Compensation_type"
                disabled={projectType === ProjectType.SUMMER}
                options={options.compensation_options}
                placeholder="Select compensation type"
                label="Compensation Type*"
                value={compensationType}
                onChange={(
                  e: any,
                  { name, value }: { name: string; value: string }
                ) => {
                  setCompensationType(value);
                }}
              />
              <StyledSelect
                error={showErrors && !quarter}
                name="Quarter"
                disabled={projectType === ProjectType.SUMMER}
                options={
                  projectType === ProjectType.YR_CS
                    ? options.non_summer_quarter_options
                    : options.quarter_options
                }
                placeholder="Select a quarter"
                label="Quarter*"
                value={quarter}
                onChange={(
                  e: any,
                  { name, value }: { name: string; value: string }
                ) => {
                  setQuarter(value);
                }}
              />
              <StyledSelect
                error={showErrors && !year}
                name="Year"
                options={options.academic_year_options}
                placeholder="Select a school year"
                label="School Year*"
                value={year}
                onChange={(
                  e: any,
                  { name, value }: { name: string; value: string }
                ) => {
                  setYear(value);
                }}
              />
              {projectType !== ProjectType.NON_CS && (
                <>
                  <StyledSelect
                    search
                    error={showErrors && !field1}
                    name="Field1"
                    options={options.field_options}
                    placeholder="Select a field of research"
                    label="First Field of Research*"
                    value={field1}
                    onChange={(
                      e: any,
                      { name, value }: { name: string; value: string }
                    ) => {
                      setField1(value);
                    }}
                  />
                  <StyledSelect
                    search
                    name="Field2"
                    clearable
                    options={options.field_options}
                    placeholder="Select a field of research"
                    label="Second Field of Research"
                    value={field2}
                    onChange={(
                      e: any,
                      { name, value }: { name: string; value: string }
                    ) => {
                      setField2(value);
                    }}
                  />
                  <StyledSelect
                    search
                    name="Field3"
                    clearable
                    options={options.field_options}
                    placeholder="Select a field of research"
                    label="Third Field of Research"
                    value={field3}
                    onChange={(
                      e: any,
                      { name, value }: { name: string; value: string }
                    ) => {
                      setField3(value);
                    }}
                  />
                </>
              )}
              <Field name="Description" validate={required}>
                {({ input, meta }) => (
                  <div style={{ marginBottom: "18px" }}>
                    <StyledTextArea
                      name={input.name}
                      label="Project Description*"
                      value={input.value}
                      onChange={input.onChange}
                      error={meta.error && meta.touched && meta.error}
                    />
                  </div>
                )}
              </Field>
              <Field name="Prerequisites" validate={required}>
                {({ input, meta }) => (
                  <div style={{ marginBottom: "18px" }}>
                    <StyledTextArea
                      name={input.name}
                      label="Prerequisites*"
                      value={input.value}
                      onChange={input.onChange}
                      error={meta.error && meta.touched && meta.error}
                    />
                  </div>
                )}
              </Field>
              <Field name="Recommened_background" validate={required}>
                {({ input, meta }) => (
                  <div style={{ marginBottom: "18px" }}>
                    <StyledTextArea
                      name={input.name}
                      label="Recommended Background*"
                      value={input.value}
                      onChange={input.onChange}
                      error={meta.error && meta.touched && meta.error}
                    />
                  </div>
                )}
              </Field>
              <Field name="Project_url">
                {({ input, meta }) => (
                  <div style={{ marginBottom: "18px" }}>
                    <StyledInput
                      name={input.name}
                      label="Project URL"
                      value={input.value}
                      onChange={input.onChange}
                      error={meta.error && meta.touched && meta.error}
                    />
                  </div>
                )}
              </Field>
              <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                  <div>
                      <Header3>NUMBER OF PROJECT POSITIONS</Header3>
		              <div>
                      <Paragraph>
                      Students tagged as CURIS Fellows are free. In addition, the department allocates two free funded positions per CS faculty member. Faculty members can optionally fund additional non-CURIS Fellows. We will match up to your maximum number of *funded* positions not including the CURIS Fellows, and up to the maximum *total* positions including CURIS Fellows. Matches will be performed with the Gale–Shapley algorithm.
                      </Paragraph>
                      </div>
                  </div>
              </div>
              <Field name="Number_of_students" validate={required}>
                {({ input, meta }) => (
                  <div style={{ marginBottom: "18px" }}>
                    <StyledInput
                      type="number"
                      name={input.name}
                      label="What is the maximum *total* positions to match to this project? (e.g., mark 2 if you would not want more than two students, no matter how many are free)*"
                      value={input.value}
                      onChange={input.onChange}
                      error={meta.error && meta.touched && meta.error}
                    />
                  </div>
                )}
              </Field>
 	      <Field name="Number_of_funded_students" validate={required}>
                {({ input, meta }) => (
                  <div style={{ marginBottom: "18px" }}>
                    <StyledInput
                      type="number"
                      name={input.name}
                      label="What is the maximum *funded* positions to match to this project? This funding may come from faculty research funds or from the two free funded positions per faculty.*"
                      value={input.value}
                      onChange={input.onChange}
                      error={meta.error && meta.touched && meta.error}
                    />
                  </div>
                )}
              </Field>
              <Field name="Professor_name" validate={required}>
                {({ input, meta }) => (
                  <div style={{ marginBottom: "18px" }}>
                    <StyledInput
                      name={input.name}
                      label="Sponsoring Faculty Name*"
                      value={input.value}
                      onChange={input.onChange}
                      error={meta.error && meta.touched && meta.error}
                    />
                  </div>
                )}
              </Field>
              <Field
                name="Professor_email"
                validate={composeValidators(required, email)}
              >
                {({ input, meta }) => (
                  <div style={{ marginBottom: "18px" }}>
                    <StyledInput
                      name={input.name}
                      label="Contact Email (faculty or grad student)*"
                      value={input.value}
                      onChange={input.onChange}
                      error={meta.error && meta.touched && meta.error}
                    />
                  </div>
                )}
              </Field>
              {projectType === ProjectType.SUMMER && (
                <div>
                  <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                    <Header3>OFFICE HOURS</Header3>
                    <div>
                      <Paragraph>
                        Please list dates, times and locations (virtual or physical) for applicant office hours during the dates listed as "<b>Project office hours</b>" at <Link to="/summer/" target="_blank">https://curis.stanford.edu/summer/</Link>. During these office hours, students will be able to learn more about your project and ask questions. <br />
                        Office hour times listed under <i>Public Office Hours</i> will be visible on the main <Link to="/summer_projects/" target="_blank">summer projects page</Link>, and those listed under <i>Outreach Office Hours</i> will only be visible at <Link to="/summer_projects_outreach_oh/" target="_blank">this link</Link>, which will be shared with students from engineering diversity organizations.
                      </Paragraph>
                    </div>
                  </div>

                  <Field name="Public_OH" validate={required}>
                    {({ input, meta }) => (
                      <div style={{ marginBottom: "18px" }}>
                        <StyledTextArea
                          name={input.name}
                          label="Public Office Hours (date, time, location)*"
                          value={input.value}
                          onChange={input.onChange}
                          error={meta.error && meta.touched && meta.error}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="Outreach_OH" validate={required}>
                    {({ input, meta }) => (
                      <div style={{ marginBottom: "18px" }}>
                        <StyledTextArea
                          name={input.name}
                          label="Outreach Office Hours (date, time, location)*"
                          value={input.value}
                          onChange={input.onChange}
                          error={meta.error && meta.touched && meta.error}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              )}
              
              {error && (
                <>
                  <br />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography color={COLORS.MED_RED}>
                      There was an issue creating your listing. Please try
                      again.
                    </Typography>
                  </div>
                </>
              )}
              {created && (
                <>
                  <br />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography color={COLORS.DARK_GREY}>
                      Your project was created!
                    </Typography>
                  </div>
                </>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "30px",
                }}
              >
                <GreyButton
                  style={{ marginRight: "23px" }}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </GreyButton>
                <CurisButton
                  type="submit"
                  // style={{ marginTop: "60px" }}
                  disabled={loading || created}
                >
                  {!loading ? (
                    "Create Project"
                  ) : (
                    <Loader active inline size="mini" inverted />
                  )}
                </CurisButton>
              </div>
            </form>
          )}
        />
      </Form>
    </>
  );
};

const StyledTextArea = styled(Form.TextArea)`
  font-family: Lato !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 29px !important;
  // padding: 13px !important;

  // min-height: 228px;
  margin-top: 6px;
`;

export const StyledModal = styled(Modal)`
  padding: 30px;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GreyButton = styled(Button)`
  color: white !important;
  background-color: ${COLORS.MED_GREY} !important;

  &: hover {
    background-color: ${COLORS.MED_GREY} !important;
  }
`;

const StyledSelect = styled(Form.Select)`
  font-family: Lato !important;
  font-size: 18px !important;
  line-height: 29px !important;
  // font-weight: 600 !important;
  color: ${COLORS.DARK_GREY} !important;

  & label {
    font-family: Lato !important;
    font-size: 18px !important;
    line-height: 29px !important;
    // font-weight: 600 !important;
  }

  & input {
    padding: 13px !important;
  }
`;

const StyledInput = styled(Form.Input)`
  font-family: Lato !important;
  font-size: 18px !important;
  line-height: 29px !important;
  font-weight: 600 !important;
  color: ${COLORS.DARK_GREY} !important;

  & label {
    font-family: Lato !important;
    font-size: 18px !important;
    line-height: 29px !important;
    font-weight: 600 !important;
  }

  & input {
    padding: 13px !important;
  }
`;
